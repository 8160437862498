.containerPortifolios {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100vh;
    max-width: 80%;
    position: relative;
    padding-left: 5%;
}

.latestPortifolios {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    max-height: 200px;
    align-content: center;
    padding-inline: 50px;
}