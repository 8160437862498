.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 20px;
    height: 90vh;
    background-color: #fff;
}

.formWrapper {
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
}

.title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #004d40;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 1.2rem;
    text-align: center;
    color: #666;
    margin-bottom: 30px;
}

.form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.fullWidth {
    grid-column: span 3;
}

.ant-form-item {
    margin-bottom: 20px;
}

.ant-input {
    border-radius: 5px;
    padding: 10px;
}

button.ant-btn-primary {
    background-color: #004d40;
    border: none;
    width: 100%;
    height: 40px;
    font-size: 1rem;
}

button.ant-btn-primary:hover {
    background-color: #00332c;
}

.button {
    width: 100%;
    height: 50px;
    font-size: 16px;
    background-color: #27d8a1;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #1ea885;
}

.full-width {
    grid-column: span 3;
}