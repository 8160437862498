:root {
    --primary: #27d8a1;
    --text: #ffffff;
    --invert-text: #000000;
}

.backdrop {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    display: flex;
    background-image: linear-gradient(#27d8a1, #000);
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container-signup {
    width: 600px;
    height: 600px;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.header-text {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary);
    text-align: center;
    margin-bottom: 20px;
}

.description-text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;
}

.signup-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 50%;
}

.input {
    flex: 0 0 48%;
    margin-bottom: 20px;
}

.btn-submit {
    width: 100%;
    height: 50px;
    font-size: 16px;
    background-color: var(--primary);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.btn-submit:hover {
    background-color: darken(var(--primary), 10%);
}

.btn-back {
    width: 100%;
    height: 50px;
    font-size: 16px;
    color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: color, background-color 0.3s;
    margin-top: 10px;
}

.btn-back:hover {
    color: #bbb;
    background-color: rgba(255, 255, 0, 0.4) !important;
}