:root {
    --primary: #27d8a1;
    --text: #ffffff;
    --background: #121212;
    --input-background: #1e1e1e;
    --input-border: #333;
    --button-background: #27d8a1;
    --button-hover: #1ea885;
}


.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 20px;
    height: 90vh;
    background-color: var(--background);
}

.formWrapper {
    border-radius: 10px;
    padding: 30px;
    background-color: var(--input-background);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: var(--primary);
    margin-bottom: 20px;
}

.subtitle {
    font-size: 1.2rem;
    text-align: center;
    color: var(--text);
    margin-bottom: 30px;
}

.form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.fullWidth {
    grid-column: span 3;
}

.ant-form-item {
    margin-bottom: 20px;
}

.ant-input {
    border-radius: 5px;
    padding: 10px;
    background-color: var(--input-background);
    border: 1px solid var(--input-border);
    color: var(--text);
}

button.ant-btn-primary {
    background-color: var(--button-background);
    border: none;
    width: 90%;
    height: 40px;
    font-size: 1rem;
    color: var(--text);
}

button.ant-btn-primary:hover {
    background-color: var(--button-hover);
}

.button {
    width: 100%;
    height: 50px;
    font-size: 16px;
    background-color: var(--button-background);
    color: var(--text);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: var(--button-hover);
}

.full-width {
    grid-column: span 3;
}