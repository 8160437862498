:root {
    --primary: #27d8a1;
    --text: #ffffff;
    --invert-text: #000000;
}

.backdrop {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    display: flex;
    background-image: linear-gradient(#27d8a1, #000);
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container-login {
    min-width: 350px;
    min-height: 450px;
    border-radius: 5px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.icon {
    width: 150px;
    height: 150px;
    display: flex;
    align-self: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 135px;
    min-width: 250px;
}


.btn-submit {
    width: 240px;
}
.signup-link, .signup-link a {
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}