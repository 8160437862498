body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body * {
  transition: color 0.1s ease-in-out;
}

a {
  color: #1a8a66;
}

.recharts-tooltip-label {
  color: #000 !important;
}

.bshadow {
  box-shadow: 1px 1px 3px 0px rgba(128, 128, 128, 0.2);
}

:root {
  transition: background-color 0.2s ease-in-out;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(63, 145, 120, 0.548) inset !important;
}

@media print {

  .MuiPaper-root,
  .ant-menu {
    display: none !important;
  }
}
.ant-timeline-item-head {
  background-color: transparent !important;
}