.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.header div {
    display: flex;
    align-items: center;
    width: 320px;
    justify-content: space-between;
}

.render-item {
    transition: width 0.3s ease-in-out;
}

.grid {
    box-shadow: 1px 1px 5px 0px rgba(128, 128, 128, 0.3);
}

.view-icon {
    font-size: 30px;
    cursor: pointer;
    color: --primary-color;
}


.list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.list .ant-card-actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 0;
    padding-right: 10px;
}

.ant-card-actions li {
    width: 100% !important;
    border: none !important;
}

.list .ant-card-body {
    width: 60%;
}

.grid .ant-card-body {
    width: 100%;
    padding-inline: 10px;
    min-height: 12rem;
}

.list .ant-card-cover .product-image {
    width: 250px;
    height: 200px;
    border-top-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 10px;
    margin-block: 5px;
}

.grid .ant-card-cover  {
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.grid .ant-card-cover .product-image {
    height: 200px;
    width: 95%;
    margin-top: 5%;
    padding-bottom: 10px;
    justify-content: center;
    border-radius: 7.5px;
    display: flex;
    flex-direction: row;
}

.colored {
    color: #1a8a66;
}

.footer-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.list .footer-btn {
    width: 100%;
}

.ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.btn-remove {
    display: inline-flex;
    align-items: center;
}

.btn-remove:hover {
    color: #ec6500 !important;
    border-color: #ec6500 !important;
}

.btn-remove:disabled {
    border: 0;
    color: inherit !important;
}

.image-upload {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.image-upload>span {
    display: flex !important;
    justify-content: center;
}


.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.end {
    display: flex;
    justify-content: flex-end;
}

.end>* {
    width: unset !important;
}

.end .ant-form-item-control-input-content>* {
    padding-inline: 2vw;
    margin-left: 3vw;
}

.row:has(> :last-child:nth-child(1))>* {
    width: 100%;
}

.row:has(> :last-child:nth-child(2))>* {
    width: 48%;
}

.row:has(> :last-child:nth-child(3))>* {
    width: 30%;
}

.row:has(> :last-child:nth-child(4))>* {
    width: 20%;
}