@import url('https://fonts.cdnfonts.com/css/inter');

:root {
    --primary: #27d8a1;
    --text: #ffffff;
    --invert-text: #000000;
}

.container {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-color: black;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
}

.primary {
    color: var(--primary);

}

.platform-button-label {
    color: var(--primary);
    font-weight: bolder;
    font-size: 18px;
    font-family: 'Inter', sans-serif;

}

.laptop-icon {
    color: #27d8a1;
    margin-inline: 8px;
}

.commonText {
    color: var(--invert-text);
    font-size: 18px;
}

.lightText {
    color: var(--text);
    font-size: 18px;
}

.nav {
    position: fixed;
    width: 60%;
    height: 85px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    padding: 10px;
}

.platform-button {
    background-color: #fff;
    padding: 15px;
    position: absolute;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    text-indent: center;
    cursor: pointer;
}

.copy {
    color: var(--text);
    font-family: 'Inter', sans-serif;
    font-size: 35px;
    font-weight: normal;
}

.copyMobile {
    color: var(--text);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: normal;
}

.bold {
    font-family: 'Inter', sans-serif;
    font-weight: bolder;
}

.leftContent {
    min-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-image: linear-gradient(black, #27d8a1);
}

.leftContentMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-image: linear-gradient(black, #27d8a1);
}

.rightContent {
    width: 60%;
    background-color: var(--invert-text);
    display: flex;
    justify-content: space-between;
}

.rightContentMobile {
    width: 100%;
    background-color: var(--invert-text);
    display: flex;
    justify-content: space-between;
}

.logo {
    position: absolute;
    top: -30px;
}

.phonemock {
    position: relative;
    margin-top: 120px;
}

.divider {
    height: 100%;
    top: 0;
    z-index: 0;
    align-self: flex-start;
    display: flex;
    margin-inline: -105px;
}

.field {
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fieldMobile {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}