.container {
    padding-inline: 1vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 85vh;
    overflow-y: hidden;
}

.pdv {
    width: 67%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.pdv .header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 10vh;
}

.pdv .header>* {
    width: 25vw;
}

.pdv .header .divider {
    width: 5vw;
    text-align: center;
}

.pdv .products {
    overflow-y: auto;
    overflow-x: hidden;
    height: 60vh;
}

.productSpace {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 2vw;
    height: 8rem;
    padding-block: 1vh;
    gap: 1rem;
    border-radius: 0.8rem;
    border: 1px groove rgba(39, 216, 161, 0.25);
    margin: 1vh;
}

.productSpace .productQuantity {
    height: 6rem;
    width: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    ;
}

.checkout {
    width: 33%;
    display: flex;
    flex-direction: column;
    margin-right: 2vw;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    max-height: 85vh;
    overflow-y: auto;
}

.checkout .addClient {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.total {
    width: 95%;
}

.total .label {
    align-self: flex-start;
    text-align: left;
    padding-block: 0.2vh;
}

.total .value {
    align-self: flex-end;
    text-align: right;
}

.productSpace .fixedDeleteIcon {
    width: 100%;
}