.listItem {
    width: 100%;
    transition: all 0.3s ease-in-out;
    box-shadow: 1px 1px 2px 0px rgba(128, 128, 128, 0.3);

}

.cardHeader {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
}

.cardHeader>b {
    width: 15%;
}
.cardHeader>b>span {
    font-size: 18px;
}

.cardBody {
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0) !important;
}

.cardBody::before,
.cardBody::after {
    display: none !important;
    content: none !important;
}

.cardActions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 0;
    width: 20%;
    height: 5rem;
}

.cardBody .statistics {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-between;
}

.cardBody .statistics>* {
    width: 50%;
}

.cardActions li {
    width: 12% !important;
    border: none !important;
}

.cardActionsBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}